<template>
  <div class="audience-page">
    <page-header
      :title="$t('saveAudience')"
      :subtitle="$t('findAudience')"
      :show-action="false"
    />
    <div class="audience-list">
      <div class="audience-list-header font-bold flex w-full bg-white p-6 rounded text-base">
        <div class="flex-one w-1/3 uppercase">
          {{ $t('nom') }}
        </div>
        <div class="flex-one w-1/3 text-right uppercase">
          {{ $t('date') }}
        </div>
        <div class="flex-one w-1/3 text-right uppercase">
          {{ $t('invoiceAction') }}
        </div>
        <div class="flex-one text-white w-1/7">
          <div class="flex-one text-white w-1/7 invisible">
            <svg-icon
              name="ic-more"
              class="w-4 h-4"
              fill-current
            />
          </div>
        </div>
      </div>
      <div class="audience-list">
        <div
          v-for="(item, index) in audience"
          :key="item+index"
          class=""
        >
          <audience-item-vue :data="item"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import PageHeader from '../../components/helper/rema-components/page-header'
import { firebaseDb } from '../../main'
import { firebaseRef } from '../../rema-function/firebaseRef'
import AudienceItemVue from './audience-item.vue'

export default {
  name: 'Audience',
  components: {
    PageHeader,
    AudienceItemVue
  },
  data () {
    return {
      audience: []
    }
  },
  firebase () {
    return {
      audience: firebaseDb.ref(firebaseRef.pubAudience).child(firebase.auth().currentUser.uid).orderByChild('createdAt')
    }
  }
}

</script>

<style>

</style>
